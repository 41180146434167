import {RestService} from "../core/_base/crud/services/_rest.service";
import {Signboard} from "../model/signboard";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class SignBoardService extends RestService<Signboard> {

	defaultSortBy(): string {
		return "name";
	}

	constructor(http: HttpClient) {
		super(http, 'sign-boards');
	}
}

