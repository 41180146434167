import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {RestService} from "../../services/_rest.service";
import {DomainObject} from "../../models/_domain.model";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin, Observable, of} from "rxjs";

@Component({
  selector: 'gt-base-edit',
  templateUrl: './base-edit.component.html',
  styleUrls: ['./base-edit.component.scss']
})
export abstract class BaseEditComponent<T extends DomainObject> extends BaseComponent {

	public object: T

	protected restService: RestService<T>
	protected route: ActivatedRoute
	protected router: Router

	abstract viewDidLoad()
	abstract newEntity(): T
	abstract didStartSaving()
	abstract didFinishSaving()

	constructor(restService: RestService<T>, route: ActivatedRoute, router: Router, protected changeDetector: ChangeDetectorRef) {
		super();
		this.restService = restService
		this.route = route
		this.router = router
	}

	onInit() {
		this.route.params.subscribe(params => {

			if(params['id'] === 'new') {
				this.object = this.newEntity()
				this.changeDetector.detectChanges()
				this.viewDidLoad()
				return
			}

			this.restService.findOne(params['id']).subscribe(object => {
				this.object = object
				this.changeDetector.detectChanges()
				this.viewDidLoad()
			})
		})
	}

	/*
	 * override this method to perform the saving of the child objects
	 */
	saveChilds(mainObject: T): [Observable<any>] {
		return [of(null)]
	}

	save() {
		this.didStartSaving()
		this.restService.create(this.object).subscribe(result => {
			forkJoin(this.saveChilds(result[0])).subscribe( _ => {
				this.didFinishSaving()
				this.router.navigate(['../'], {relativeTo: this.route});
			})
		})
	}



}
