import {RestService} from "../core/_base/crud/services/_rest.service";
import {BusinessName} from "../model/business-name";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class BusinessNameService extends RestService<BusinessName> {

	defaultSortBy(): string {
		return "name";
	}

	constructor(http: HttpClient) {
		super(http, "business-names");
	}
}

