import {RestService} from "../core/_base/crud/services/_rest.service";
import {ServiceType} from "../model/service-type";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ServiceTypeService extends RestService<ServiceType> {

	constructor(http: HttpClient) {
		super(http, "service-types");
	}

	defaultSortBy(): string {
		return "name";
	}

}
