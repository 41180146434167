import {RestService} from "../core/_base/crud/services/_rest.service";
import {Location} from "../model/location";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class LocationService extends RestService<Location> {

	constructor(http: HttpClient) {
		super(http, "locations");
	}

	defaultSortBy(): string {
		return "name";
	}

}
