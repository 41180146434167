import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {DomainObject} from "../../models/_domain.model";
import {RestService} from "../../services/_rest.service";
import {map} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryParamsModel} from "../..";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'gt-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss']
})
export abstract class BaseListComponent<T extends DomainObject> extends BaseComponent {

	objects = new BehaviorSubject<T[]>([])
	protected restService: RestService<T>

	abstract viewDidLoad()

	protected constructor(restService: RestService<T>, protected router: Router, protected route: ActivatedRoute) {
		super();
		this.restService = restService
	}

	loadItems() {
		// TODO: change this when pagination is implemented
		this.restService.findAll(QueryParamsModel.maxPageSize()).subscribe(result => { this.objects.next(result.content) })
	}

	onInit() {
		this.loadItems()
		this.viewDidLoad()
	}

	edit(object: T) {
		this.router.navigate([object.id], {relativeTo: this.route});
	}

	createNew() {
		this.router.navigate(['new'], {relativeTo: this.route});
	}

	delete(object: T) {
		// TODO: ask for confirmation
		this.restService.deleteOne(object).subscribe(_ => this.loadItems())
	}



}

