import {RestService} from "../core/_base/crud/services/_rest.service";
import {Store} from "../model/store";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class StoreService extends RestService<Store> {

	constructor(http: HttpClient) {
		super(http, 'stores');
	}

	defaultSortBy(): string {
		return "signBoard.name";
	}

}
